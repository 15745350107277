import React from 'react';
import dog404 from './dog-404-2.png';
import './App.css';

function Dog404() {
  return (
    <div className="App">
      <header className="App-header">
        <h2>
          404 Not Found
        </h2>
      </header>
      <div className="App-body">
        <img
          className={"main-photo"}
          src={dog404}
          alt="dog 404"
        />
      </div>
    </div>
  );
}

export default Dog404;
