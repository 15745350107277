import * as React from 'react';
import engagement from './engagement.jpg';
import greyleigh from './greyleigh-map.png';
import './App.css';

function App() {
  const [user, setUser] = React.useState(null);

  const handleLogin = () =>
    setUser({
      id: '1',
      name: 'robin',
      permissions: ['analyze'],
      roles: ['admin'],
    });

  const handleLogout = () => setUser(null);

  return (
    <div className="App">
      <header className="App-header">
        <h2>
          Celebrating the wedding of
        </h2>
        <h1>
          Lusiana and Paul
        </h1>
      </header>
      <div className="App-body">
        <img
          className={"main-photo"}
          src={engagement}
          alt="mt cook engagement"
        />
        <div className={"merege-details"}>
            <h2 className={"merege-date"}>
              <div>
                30
              </div>
              <div className={"merege-month"}>
                DECEMBER
              </div>
              <div>
                2024
              </div>
            </h2>
          <h4 className="merege-time">
            at two o'clock in the afternoon
          </h4>
          <h2>
            <a
              className="App-link"
              href="https://www.greyleigh.com.au/"
              target="_blank"
              rel="noopener noreferrer"
            >
              GREYLEIGH KIAMA
            </a>
          </h2>
          <div style={{ marginTop: "4rem" }}>
            <a
                className="Map-link"
                href="https://maps.app.goo.gl/vYP2pSQR5HE32VrM6"
                target="_blank"
                rel="noopener noreferrer"
                >
              <img
                className={"map-photo"}
                src={greyleigh}
                alt="greyleigh map"
                />
            </a>
          </div>


        </div>
      </div>
    </div>
  );
}

export default App;
