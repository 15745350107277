import React from 'react';
import lalirose from './lali_bg.jpg';
import './App.css';

function Dashboard() {
  return (
    <div className="App">
      <header className="App-header">
        <h2>
          RSVP
        </h2>
        <h1>
          Lusiana and Paul
        </h1>
      </header>
      <div className="App-body">
        <img
          className={"main-photo"}
          src={lalirose}
          alt="lali rose"
        />
      </div>
    </div>
  );
}

export default Dashboard;
